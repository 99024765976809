import React, { useContext } from 'react'
import TotalFranchise from './TotalFranchise';
import CreateFranchise from './CreateFranchise';
import { MyContext } from '../../../Context/MyContextProvider';

function Franchisepage() {
    const { FranchisNavbar, setFranchisNavbar } = useContext(MyContext);
    const user = localStorage.getItem('userdata');
    const loginUser = JSON.parse(user);

    const StudentHandler = () => {
        setFranchisNavbar(false)
    };

    const triggerListing = () => {
        setFranchisNavbar(true)
    };


    return (
        <div className='w-full'>
            <div className="w-full sidebar-same border-b h-16">
                <div className="w-[95%] mx-auto px-4 py-2 flex justify-end">
                    <div className="w-[80%]">

                    </div>
                    <div className="w-[20%] pl-2 flex justify-between">
                        {
                            loginUser?.role === "director" &&
                            <>
                                <div onClick={StudentHandler}
                                    className={!FranchisNavbar ? 'cursor-pointer text-white  border-b-2 border-[#2e3193] hover:border-white  mt-2 px-4 rounded  md:hover:bg-transparent   md:p-0 dark:text-white   dark:hover:text-white md:dark:hover:bg-transparent' : 'cursor-pointer text-white  border-b-2 border-white hover:border-[#ff0000]  mt-2 px-4  rounded  md:hover:bg-transparent   md:p-0 dark:text-white   dark:hover:text-white md:dark:hover:bg-transparent'}>
                                    Franchise
                                </div>
                                <button onClick={triggerListing} data-tooltip-target="tooltip-new" type="button"
                                    className="inline-flex items-center justify-center w-10 h-10 font-medium bg-[#ff0000] rounded-full hover:bg-[#e63535] group focus:ring-4 focus:ring-[#ff0000] focus:outline-none dark:focus:ring-[#ff0000]">
                                    <svg className="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                        viewBox="0 0 18 18">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                            d="M9 1v16M1 9h16" />
                                    </svg>
                                    <span className="sr-only">New item</span>
                                </button>
                            </>
                        }


                        {
                            loginUser?.role === "franchise" && 
                            <>
                             <div onClick={StudentHandler}
                                    className={!FranchisNavbar ? 'cursor-pointer text-white  border-b-2 border-[#ff0000] hover:border-white  mt-2 px-4 rounded  md:hover:bg-transparent   md:p-0 dark:text-white   dark:hover:text-white md:dark:hover:bg-transparent' : 'cursor-pointer text-white  border-b-2 border-white hover:border-[#ff0000]  mt-2 px-4  rounded  md:hover:bg-transparent   md:p-0 dark:text-white   dark:hover:text-white md:dark:hover:bg-transparent'}>
                                    Franchise
                                </div>
                            </>
                        }

                    </div>
                </div>
            </div>
            {
                FranchisNavbar ? <CreateFranchise /> : <TotalFranchise />
            }

        </div>

    )
}

export default Franchisepage