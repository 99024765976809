import React, { useContext, useEffect, useRef, useState } from 'react';
import new_a_m from '../../../Assets/new_a_m.png'
import { MyContext } from '../../../Context/MyContextProvider';
import { IoCloseSharp } from "react-icons/io5";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Http from '../../../Services/Http'


function M_Marksheet() {
    const token = localStorage.getItem('token');

    const { setMarksheet } = useContext(MyContext);
    const cartificateData = localStorage.getItem('cartificateData');
    const newCartificate = JSON.parse(cartificateData);
    const [pastCartificate, setpastCartificate] = useState({});
    const [inputClose, setinputClose] = useState(false);
    // const [serialno, setserialno] = useState('');
    const [issuedate, setissuedate] = useState('');


    // console.log(pastCartificate);
    const [serialno, setSerialno] = useState('');
    const [course, setCourse] = useState('');
    const [courseperiod, setCourseperiod] = useState('');
    const [fullmarks, setFullmarks] = useState('');
    const [pmarks, setPmarks] = useState('');
    const [grade, setGrade] = useState('');
    const [Written, setWritten] = useState('');
    const [Practical, setPractical] = useState('');
    const [Assignment, setAssignment] = useState('');
    const [Viva_Voce, setViva_Voce] = useState('');
    const [totalmarks, setTotalmarks] = useState('');
    const [Percentage, setPercentage] = useState('');
    // const [category, setCategory] = useState('');
    const [image, setimage] = useState('')


    // const [PDFbutton, setPDFbutton] = useState(false);
    const [SubmitButton, setSubmitButton] = useState(true);
    const [photo, setPhoto] = useState('');

    useEffect(() => {
        if (pastCartificate) {
            const { course, serialno, courseperiod, fullmarks, pmarks, grade, issuedate, studentid,
                Written, Practical, Assignment, Viva_Voce, totalmarks, Percentage, } = pastCartificate;
            setSerialno(serialno);
            setCourse(course);
            setissuedate(issuedate);
            setCourseperiod(courseperiod);
            setFullmarks(fullmarks);
            setTotalmarks(totalmarks);
            setPmarks(pmarks);
            setGrade(grade);
            setWritten(Written);
            setPractical(Practical);
            setAssignment(Assignment);
            setViva_Voce(Viva_Voce);
            setTotalmarks(totalmarks);
            setPercentage(Percentage);
            setPhoto(studentid?.image)
        }
    }, [pastCartificate])

    const handleClose = () => {
        setMarksheet(false)
    }
    const certificateRef = useRef(null);
    const handleDownloadPDF = () => {
        setinputClose(true);

        const input = certificateRef.current;
        setTimeout(() => {
            html2canvas(input, { scale: 2 }) // Increase scale for higher resolution
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF('p', 'mm', 'a4'); // Set orientation to landscape
                    pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // A4 size: 297mm × 210mm (switch width and height for p)
                    pdf.save('Marksheet.pdf');
                    setinputClose(false);
                });
        }, 500);

    }

    ////////   get certificate  /////////

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await Http({
                    url: `/payment/getmarksheet`,
                    method: "post",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    data: { studentid: newCartificate?._id, category: 'ADCA' }
                });
                setpastCartificate(res?.data?.markSheet);

                if (res?.data?.message === "Mark sheet found") {

                    setSubmitButton(false)

                } if (res?.data?.message === "Mark sheet not found") {
                    setSubmitButton(true)
                }
                if (res?.data?.MarkSheet === null) {
                    setSubmitButton(false)

                }
            } catch (error) {
                console.log(`cartificate get error ${error}`);
            }
        }
        fetchData()
    }, [newCartificate?._id, setMarksheet, token])

    ////////   update certificate   /////////

    const markSheetUpdateHandler = async () => {
        try {
            let res = await Http({
                url: `/payment/updatemarksheet`,
                method: "patch",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: {
                    _id: pastCartificate?.m_id, studentid: pastCartificate?.studentid?._id, serialno, category: "ADCA",
                    fullmarks, totalmarks, grade, issuedate, image: image,courseperiod,
                    Written, Practical, Assignment, Viva_Voce, Percentage
                }
            });
            if (res?.data?.message === "updatedMarkSheet_Created_201") {
                setMarksheet(false)
            }
        } catch (error) {
            console.log(`cartificate error ${error}`);
        }
    }
    const serialnoHandler = (item) => {
        if (item) {
            const dateObject = new Date(item); // Parse the date string into a Date object
            return dateObject.getFullYear(); // Return the year extracted from the Date object
        } else {
            // Handle cases where item is null or undefined
            return null; // or throw an error, depending on your requirements
        }
    };
    const handleImageUpload = (event) => {
        const imageFile = event.target.files[0];
        if (imageFile) {
            const reader = new FileReader();
            reader.onload = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    // Set canvas dimensions to the image dimensions
                    canvas.width = img.width;
                    canvas.height = img.height;

                    // Draw the image on the canvas
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    // Adjust compression quality in a loop until file size is <= 10KB
                    let quality = 0.9; // Start with high quality
                    let compressedImageData;
                    do {
                        compressedImageData = canvas.toDataURL("image/jpeg", quality);
                        const base64Length = compressedImageData.length - compressedImageData.indexOf(",") - 1;
                        const fileSizeKB = (base64Length * 3) / 4 / 1024; // Convert Base64 size to KB
                        if (fileSizeKB <= 10) break; // Stop if file size is within limit
                        quality -= 0.1; // Reduce quality
                    } while (quality > 0);

                    if (compressedImageData) {
                        // Do something with the compressed image data
                        setPhoto(compressedImageData);
                        setimage(compressedImageData);
                    } else {
                        console.error("Failed to compress the image within 10KB limit.");
                    }
                };
            };
            reader.readAsDataURL(imageFile);
        }
    };
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="sidebar-same relative w-[90%] h-[95%] my-10 mx-auto  rounded-lg shadow-lg">
                <div onClick={handleClose} className='absolute top-2 right-2 cursor-pointer'>
                    <IoCloseSharp size={30} />
                </div>
                <div className='w-full h-full overflow-scroll'>
                    <div ref={certificateRef} className='h-[297mm] w-[210mm]' style={{ position: 'relative' }}>
                        <img src={new_a_m} alt='A_Certificat' className='w-full h-full' style={{ position: 'absolute', top: 0, left: 0 }} />
                        {
                            photo ?
                                <img src={photo} alt='no able ' className='w-28 h-36 absolute z-50 top-[230px] right-14 ' />
                                :
                                <input type='file' onChange={handleImageUpload} accept='image/*' className='absolute z-50 top-[230px] right-5' />
                        }
                        <h1 className='absolute z-50 top-[230px] font-bold   text-ellipsis left-16 '>
                            <span className='pr-[113px]'>Serial No. </span>: {" "}

                            {inputClose ? (
                                <span

                                >
                                    {`${serialnoHandler(newCartificate?.toenddate)}/T/${String(newCartificate?.aadharno).slice(-4)}`}
                                </span>
                            ) : (
                                <input
                                    type='text'
                                    value={`${serialnoHandler(newCartificate?.toenddate)}/T/${String(newCartificate?.aadharno).slice(-4)}`}
                                    onChange={(e) => setSerialno(e?.target?.value)}
                                    className='bg-transparent'
                                />
                            )}
                        </h1>
                        <h1 className='absolute w-full flex justify-end right-11 z-50 top-[201px] text-[#2e3193] font-bold text-base text-ellipsis   '>
                            <span>
                                Registration No - 61/2018
                            </span>
                        </h1>

                        <h1 className='absolute z-50 top-[260px] font-bold  text-ellipsis left-16 '><span className='pr-[66px]'>Student's Name </span>:  {newCartificate?.studentname}</h1>
                        <h1 className='absolute z-50 top-[290px] font-bold  text-ellipsis left-16  '><span className='pr-[78px]'>Father's Name </span>: {newCartificate?.fathername}</h1>
                        <h1 className='absolute z-50 top-[320px] font-bold  text-ellipsis left-16  '><span className='pr-[70px]'>Mother's Name </span>: {newCartificate?.mothername}</h1>
                        <h1 className='absolute z-50 top-[350px] font-bold  text-ellipsis left-16  '><span className='pr-[85px]'>Course Name </span>: {course}</h1>

                        {/* <h1 type='text' value={''} onChange={(e) => setcourse(e.target.value)} className='w-[480px] absolute z-50 bg-transparent top-[350px] font-bold  text-ellipsis left-16 ' /> */}
                        <h1 className='absolute z-50 top-[410px] font-bold  text-ellipsis left-16  '><span className='pr-[39px]'>Study Centre Name </span>: {newCartificate?.franchise_or_director_id?.franchisename}</h1>
                        <h1 className='absolute z-50 top-[440px] font-bold  text-ellipsis left-16  '><span className='pr-[10px]'>Centre Code & Address </span>: {newCartificate?.franchise_or_director_id?.fulladdress}</h1>

                        {/* <input type='text' value={DateTime(newCartificate?.startDate, "DDMMYYYY",)} onChange={(e) => setstartdate(e.target.value)} className='w-32  absolute z-50 bg-transparent top-[520px] font-bold  text-ellipsis left-56 ' /> */}
                        {/* <input type='text' value={DateTime(newCartificate?.toenddate, "DDMMYYYY",)} onChange={(e) => setenddate(e.target.value)} className='w-32  absolute z-50 bg-transparent pl-2 top-[520px] font-bold  text-ellipsis left-[340px] ' /> */}

                        <h1  className='absolute z-50 top-[380px] font-bold  text-ellipsis left-16  '>
                            <span className='pr-[58px]'>Course Duration. </span>: {" "}

                            {inputClose ? (
                                <span

                                >
                                    {courseperiod}
                                </span>
                            ) : (
                                <input
                                    type='text'
                                    value={courseperiod}
                                    onChange={(e) => setCourseperiod(e?.target?.value)}
                                    className='bg-transparent border-[#f7811f] border-4 border-dashed outline-none'
                                />
                            )}
                        </h1>
                        {
                            inputClose ?
                                <>
                                    <span
                                        className='w-32  absolute z-50 bg-transparent pl-2 top-[909px] font-bold  text-ellipsis left-[600px] '
                                    >
                                        {grade}
                                    </span>

                                    <span
                                        className='w-64  absolute z-50 bg-transparent pl-2 top-[940px] font-bold text-base  text-ellipsis left-[70px] '
                                    >
                                        {issuedate}
                                    </span>


                                    <span
                                        className='w-28  absolute z-50 top-[705px] bg-transparent font-semibold text-ellipsis left-[600px] pl-12'
                                    >
                                        {Written}
                                    </span>

                                    <span
                                        className='w-28  absolute z-50 top-[735px] bg-transparent font-semibold text-ellipsis left-[600px] pl-12'
                                    >
                                        {Practical}
                                    </span>

                                    <span
                                        className='w-28  absolute z-50 top-[765px] bg-transparent font-semibold text-ellipsis left-[600px] pl-12'
                                    >
                                        {Assignment}
                                    </span>

                                    <span
                                        className='w-28  absolute z-50 top-[795px] bg-transparent font-semibold text-ellipsis left-[600px] pl-12'
                                    >
                                        {Viva_Voce}
                                    </span>

                                    <span
                                        className='w-20  absolute z-50 top-[880px] bg-transparent font-semibold text-ellipsis left-[610px] pl-8'
                                    >
                                        {totalmarks}
                                    </span>

                                    <span
                                        className='w-20  absolute z-50 top-[908px] bg-transparent font-semibold text-ellipsis left-[270px] pl-8'
                                    >
                                        {Percentage}
                                    </span>
                                    <h1 className='absolute z-50 top-[932px] font-bold text-xs text-ellipsis left-[220px]  '>
                                        Grade :- 100-90% E+ , 89.9-80% E , 79.9-75% A+ , 74.9-70% A , 69.9-65% B+ , 65.9-60% E , 59.9-50% C+  ,&lt;50% F
                                    </h1>
                                </>
                                :
                                <>

                                    <input type='text' value={grade} onChange={(e) => setGrade(e.target.value)} className='w-32 border-[#f7811f] border-4 border-dashed outline-none absolute z-50 bg-transparent pl-2 top-[917px] font-bold  text-ellipsis left-[600px] ' />
                                    <input type='text' value={issuedate} onChange={(e) => setissuedate(e.target.value)} className='w-64 border-[#f7811f] border-4 border-dashed outline-none absolute z-50 bg-transparent pl-2 top-[947px] font-bold text-base  text-ellipsis left-[70px] ' />

                                    <input type='number' value={Written} onChange={(e) => setWritten(e?.target?.value)}
                                        className='w-28 border-[#f7811f] border-4 border-dashed outline-none absolute z-50 top-[710px] bg-transparent font-semibold text-ellipsis left-[600px] pl-12'
                                    />
                                    <input type='number' value={Practical} onChange={(e) => setPractical(e?.target?.value)}
                                        className='w-28 border-[#f7811f] border-4 border-dashed outline-none absolute z-50 top-[740px] bg-transparent font-semibold text-ellipsis left-[600px] pl-12'
                                    />
                                    <input type='number' value={Assignment} onChange={(e) => setAssignment(e?.target?.value)}
                                        className='w-28 border-[#f7811f] border-4 border-dashed outline-none absolute z-50 top-[770px] bg-transparent font-semibold text-ellipsis left-[600px] pl-12'
                                    />
                                    <input type='number' value={Viva_Voce} onChange={(e) => setViva_Voce(e?.target?.value)}
                                        className='w-28 border-[#f7811f] border-4 border-dashed outline-none absolute z-50 top-[800px] bg-transparent font-semibold text-ellipsis left-[600px] pl-12'
                                    />

                                    <input type='text' value={totalmarks} onChange={(e) => setTotalmarks(e?.target?.value)}
                                        className='w-20 border-[#f7811f] border-4 border-dashed outline-none absolute z-50 top-[890px] bg-transparent font-semibold text-ellipsis left-[610px] pl-8' />

                                    <input type='text' value={Percentage} onChange={(e) => setPercentage(e?.target?.value)}
                                        className='w-20 border-[#f7811f] border-4 border-dashed outline-none  absolute z-50 top-[915px] bg-transparent font-semibold text-ellipsis left-[270px] pl-8' />

                                    <h1 className='absolute z-50 top-[942px] font-bold text-xs text-ellipsis left-[100px]  '>
                                        Grade :- 100-90% E+ , 89.9-80% E , 79.9-75% A+ , 74.9-70% A , 69.9-65% B+ , 65.9-60% E , 59.9-50% C+  ,&lt;50% F

                                    </h1>

                                </>
                        }




                    </div>
                </div>
                <div className='absolute top-10 right-24'>

                    {
                        SubmitButton === false &&
                        <button onClick={handleDownloadPDF} class="relative  inline-flex items-center justify-center px-10 py-4 overflow-hidden font-mono font-medium tracking-tighter text-white bg-gray-800 rounded-lg group">
                            <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-green-500 rounded-full group-hover:w-56 group-hover:h-56"></span>
                            <span class="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
                            <span class="relative" >Download PDF</span>
                        </button>
                    }

                    {
                        SubmitButton === true &&
                        <h1 className='text-white font-medium text-lg'>
                            First Create ADCA Certificate.
                        </h1>
                    }
                    {
                        SubmitButton === false &&
                        <div className='block mx-auto mt-5'>
                            <button onClick={markSheetUpdateHandler} class="relative   inline-flex items-center justify-center px-10 py-4 overflow-hidden font-mono font-medium tracking-tighter text-white bg-gray-800 rounded-lg group">
                                <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-green-500 rounded-full group-hover:w-56 group-hover:h-56"></span>
                                <span class="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
                                <span class="relative" >Update</span>
                            </button>
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default M_Marksheet