import React, { useEffect, useState } from 'react';
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import logo from '../../../Assets/logo512.png.png';
import Http from '../../../Services/Http';

function DashboardPage() {
    const navigate = useNavigate()
    const [isProfileVisible, setProfileVisible] = useState(false);
    const Data = localStorage.getItem('userdata');
    const userData = JSON.parse(Data);
    const [Franchise, setFranchise] = useState(0);
    const [getStudent, setgetStudent] = useState(0);
    const [ADCA, setADCA] = useState(0);
    const [TYPING, setTYPING] = useState(0);

    const toggleProfileVisibility = () => {
        setProfileVisible(!isProfileVisible);
    };
    const logOutHandler = () => {
        localStorage.clear();
        setTimeout(() => {
            navigate('/login')
        }, 2000)

    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await Http({
                    url: `/payment/getFranchise`,
                    method: "post",
                    data: {}
                });
                if (res?.data?.message === "totalFranchise get successfully") {
                    setFranchise(res?.data?.totalFranchise);
                }
            } catch (error) {
                console.log(`error ${error}`);
            }
        }
        fetchData()
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await Http({
                    url: `/payment/getStudent`,
                    method: "post",
                    data: {}
                });
                if (res?.data?.message === "totalStudent get successfully") {
                    setgetStudent(res?.data?.totalStudent);
                }
            } catch (error) {
                console.log(`error ${error}`);
            }
        }
        fetchData()
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await Http({
                    url: `/payment/getTotalCertificate`,
                    method: "post",
                    data: { course: "ADCA" }
                });
                if (res?.data?.message === "TotalCertificate get successfully") {
                    setADCA(res?.data?.TotalCertificate);
                }
            } catch (error) {
                console.log(`error ${error}`);
            }
        }
        fetchData()
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await Http({
                    url: `/payment/getTotalCertificate`,
                    method: "post",
                    data: { course: "TYPING" }
                });
                if (res?.data?.message === "TotalCertificate get successfully") {
                    setTYPING(res?.data?.TotalCertificate);
                }
            } catch (error) {
                console.log(`error ${error}`);
            }
        }
        fetchData()
    }, []);
    return (
        <>
            <div className='relative'>
                <div className="w-full sidebar-same border-b">
                    <div className="w-[95%] mx-auto px-4 py-2 flex justify-end">
                        <div className="w-[80%] flex justify-end">
                            <div className='bg-[#FF0000] shadow-md shadow-[#2e3193] px-4 py-1 mt-1 rounded-md'>
                                <h1 className='text-white  text-lg font-semibold uppercase '>{userData?.role ? userData?.role : "Loading"}</h1>
                            </div>
                        </div>
                        <div className="w-auto pl-2 flex justify-between">
                            <button type="button"
                                className="inline-flex shadow-md shadow-[#2e3193] text-white items-center justify-center w-10 h-10 font-medium focus:bg-[#e63535] rounded-full hover:bg-[#e63535] group focus:ring-4 focus:ring-[#ff0000] focus:outline-none dark:focus:ring-[#ff0000]"
                                onClick={toggleProfileVisibility}>
                                <FaUserCircle size={30} />
                            </button>
                        </div>
                    </div>
                </div>

                {isProfileVisible && (
                    <div className="absolute right-0 mr-5 z-10 flex justify-end w-64 text-sm  transition-opacity duration-300  opacity-100 ">
                        <div className="flex  items-center h-full w-auto justify-end">
                            <div className="max-w-xs">
                                <div className="bg-white shadow-xl rounded-lg py-3">
                                    <div className="photo-wrapper p-2">
                                        <img className="w-32 h-32 rounded-full mx-auto" src={logo} alt="John Doe" />
                                    </div>
                                    <div className="p-2">
                                        <h3 className="text-center text-xl text-gray-900 font-medium leading-8">{userData?.username}</h3>
                                        <div className="text-center text-gray-400 text-xs font-semibold">
                                            <p>{userData?.role}</p>
                                        </div>
                                        <table className="text-xs my-3">
                                            <tbody>
                                                {/* <tr>
                                                <td className="px-2 py-2 text-gray-500 font-semibold">Address</td>
                                                <td className="px-2 py-2">{userData.sad}</td>
                                            </tr> */}
                                                <tr>
                                                    <td className="px-2 py-2 text-gray-500 font-semibold">Mobile</td>
                                                    <td className="px-2 py-2">+91 {userData?.mobile}</td>
                                                </tr>
                                                <tr>
                                                    <td className="px-2 py-2 text-gray-500 font-semibold">Email</td>
                                                    <td className="px-2 py-2">{userData?.email}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="text-center my-3">
                                            <h1 onClick={logOutHandler} className="text-xs text-indigo-500 italic hover:underline hover:text-indigo-600 font-medium cursor-pointer ">Logout</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {
                userData?.role === "director" &&
                <div className="p-4 mt-10">
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-4">
                        <div className="bg-blue-100 p-4 rounded shadow-white border-l-4 border-[#ff0000] text-center">
                            <h2 className="text-xl">Total Franchise</h2>
                            <p className="text-3xl font-bold">{Franchise}</p>
                        </div>
                        <div className="bg-green-100 p-4 rounded shadow-white border-l-4 border-[#ff0000] text-center">
                            <h2 className="text-xl">Total Students</h2>
                            <p className="text-3xl font-bold">{getStudent}</p>
                        </div>
                        <div className="bg-purple-100 p-4 rounded shadow-white border-l-4 border-[#ff0000] text-center">
                            <h2 className="text-xl">Total ADCA</h2>
                            <p className="text-3xl font-bold">{ADCA}</p>
                        </div>
                        <div className="bg-yellow-100 p-4 rounded shadow-white border-l-4 border-[#ff0000] text-center">
                            <h2 className="text-xl">Total Typing</h2>
                            <p className="text-3xl font-bold">{TYPING}</p>
                        </div>
                    </div>
                </div>
            }

            <div className="bg-white p-24 rounded-full shadow-md">
                <h1 className="text-3xl text-center font-bold mb-4">Welcome to Adarsh Jankalyan</h1>
                <p className="text-lg text-center text-gray-700">Thank you for visiting!</p>
                <div className="mt-4">

                </div>
            </div>
        </>
    );
}

export default DashboardPage;
