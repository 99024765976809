import React from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import image1 from '../../../Assets/courseslider3.jpg'
import image2 from '../../../Assets/courseslider4.jpg'
import image3 from '../../../Assets/courseslider5.jpg'

function ImageSlider() {
    const images = [
        `${image1}`,
        `${image2}`,
        `${image3}`,
    ];

    const CustomPrevArrow = (props) => (
        <div
            {...props}
            className="slick-arrow"
            style={{ left: '20px', zIndex: 1, color: 'white', cursor: 'pointer', position: 'absolute', top: '50%', transform: 'translateY(-50%)', fontSize: '24px' }}
            onClick={props.onClick}
        >
            &#10094;
        </div>
    );

    const CustomNextArrow = (props) => (
        <div
            {...props}
            className="slick-arrow"
            style={{ right: '20px', zIndex: 1, color: 'white', cursor: 'pointer', position: 'absolute', top: '50%', transform: 'translateY(-50%)', fontSize: '24px' }}
            onClick={props.onClick}
        >
            &#10095;
        </div>
    );

    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };

   
    return (
        <div className='w-full md:w-3/4  lg:w-full mx-auto  md:mt-[88px]'>
            
            <Slider {...settings} >
                {images.map((image, index) => (
                    <div key={index} className="w-full md:h-[600px] object-cover">
                        <img className="w-full h-full object-cover" src={image} alt={`slider ${index + 1}`} />
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default ImageSlider;
