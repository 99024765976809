import React, { useState, useEffect } from 'react';
import { FaLock } from "react-icons/fa6";
import { MdOutlineCurrencyRupee ,MdAddCall } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import ajk_new_logo from '../../Assets/logo4.png'
import { Link } from 'react-router-dom';

function Navbar() {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;

            // Check if user has scrolled beyond a certain threshold
            setIsScrolled(scrollTop > 0);
        };

        window.addEventListener('scroll', handleScroll);

        // Remove the event listener when component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const mobileNumberHandler = (phoneNumber) => {
        window.open(`tel:${phoneNumber}`, '_blank')
    }

    return (
        <>
        <div className='w-full'>
            <div className={`bg-[#0A2A8A] h-9 w-full ${isScrolled ? 'fixed top-0' : ''}`}>
                <div className='w-[85%] flex justify-between mx-auto'>
                    <div className='flex mt-1'>
                        <h1 onClick={() => window.location.href = "mailto:ajktrust@gmail.com"}  className='text-white cursor-pointer mr-5 flex'><IoMdMail className='mt-1'/> : ajktrust@gmail.com <span className='ml-5'>|</span></h1>
                        <h1 onClick={() => mobileNumberHandler('7004322539')} className='text-white flex cursor-pointer'><MdAddCall className='mt-1'/> : +91 7004322539</h1>
                    </div>
                    <div className='flex  mt-1'>
                        <Link to={'/login'} className='text-white flex mr-5 '><FaLock className='mt-1' /><span className='mr-5'>Login</span>|</Link>
                        <Link to={'/home/dashboardpage'} className='text-white flex '><MdOutlineCurrencyRupee className='mt-1' /><span>Payment</span></Link>
                    </div>
                </div>
            </div>

            <nav className={`w-full bg-[#e7ebf5] py-4  border-gray-200  fixed z-50 ${isScrolled ? ' top-0' : ''}`}>
                <div className="w-[90%] mx-auto flex items-center justify-between">
                    <Link to={'/'} className="flex items-center">
                        <img src={ajk_new_logo}
                            alt="Logo"
                            className="w-full h-16 mr-2" />
                    </Link>
                    <div className="flex items-center space-x-4">
                        <Link to={'/'}  className="text-[#052688] text-lg  font-extrabold hover:text-[#052677]">Home</Link>
                        <Link to={'/certificateverify'}  className="text-[#052688] text-lg  font-extrabold hover:text-[#052677]">Certificate verify</Link>
                        <Link to={'/courses'}  className="text-[#052688]  text-lg font-extrabold hover:text-[#052677]">Courses</Link>
                        <Link to={'/studentquerys'}  className="text-[#052688]  text-lg font-extrabold hover:text-[#052677]">Student Query</Link>
                        <Link to={'/franchise'}  className="text-[#052688]  text-lg font-extrabold hover:text-[#052677]">Institution</Link>
                        <Link to={'/franchisequerys'}  className="text-[#052688]  text-lg font-extrabold hover:text-[#052677]">Franchise Apply</Link>
                        {/* <Link to={''}  className="text-[#052688] text-lg  font-extrabold hover:text-[#052677]">Gallery</Link> */}
                        <Link to={'/contact'}  className="text-[#052688]  text-lg font-extrabold hover:text-[#052677]">Contact</Link>
                    </div>
                </div>
            </nav>
            </div>
        </>
    )
}

export default Navbar;
