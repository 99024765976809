import React, { useContext, useEffect, useState } from 'react'
import Http from '../../../Services/Http';
import { DateTime } from 'date-time-helper-x';
import { MyContext } from '../../../Context/MyContextProvider';
import TCreatecertificate from './T_Createcertificate';
import ACreatecertificate from './A_Createcertificate';
import AMarksheet from './A_Marksheet';
import TMarksheet from './T_Marksheet';
import PaymentMethod from './PaymentMethod';

function Totalcertificate() {
  const token = localStorage.getItem('token');
  const [course, setCourse] = useState('');
  const { CertificateCreate, setCertificateCreate, paymentPage,
    setpaymentPage, T_CertificateCreate, setT_CertificateCreate, Marksheet, setMarksheet, TypingMarksheet,
  } = useContext(MyContext); // setTyingMarksheet
  const [students, setStudents] = useState([]);
  const [baseStudents, setbaseStudents] = useState([])
  const user = localStorage.getItem('userdata');
  const loginUser = JSON.parse(user);
  let newUserlogin = loginUser?.franchiserid?._id
  let studentloginid = loginUser?.studentid?._id
  const [loading, setloading] = useState(true)
  const [paymentDetail, setpaymentDetail] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await Http({
          url: `/student/getstudent`,
          method: "get",
          headers: {
            'Authorization': `Bearer ${token}`
          },
          data: {}
        });
        const students = res?.data?.students;
        setStudents(students);
        setloading(false);
      } catch (error) {
        console.log(`Server Catch Error ${error}`);
        setloading(false);
      }
    };

    fetchData(); // Call fetchData here

  }, [token]); // Empty dependency array



  useEffect(() => {
    if (loginUser.role === "director") {
      setbaseStudents(students);

    } else if (loginUser?.role === "franchise") {
      const student = students.filter((item) => {
        return item?.franchise_or_director_id?._id === newUserlogin
      })
      setbaseStudents(student)

    } else if (loginUser?.role === "student") {
      const student = students.filter((item) => {
        return item?._id === studentloginid && item?.franchise_or_director_id?._id === newUserlogin
      })
      setbaseStudents(student)

    }
  }, [newUserlogin, loginUser?.role, students, studentloginid])

  const A_CertificateHandler = (item) => {

    localStorage.setItem('cartificateData', JSON.stringify(item));
    setCertificateCreate(true)
  }
  const T_CertificateHandler = (item) => {
    setT_CertificateCreate(true)
    localStorage.setItem('cartificateData', JSON.stringify(item));
  }
  const MarkSheetHandler = (item) => {
    setMarksheet(true)
    localStorage.setItem('cartificateData', JSON.stringify(item));
  }
  // const TyingMarkSheetHandler = (item) => {
  //   setTyingMarksheet(true)
  //   localStorage.setItem('cartificateData', JSON.stringify(item));
  // }
  const PaymentPageADCA = (item) => {
    const paymentStudent = JSON.stringify(item)
    localStorage.setItem("paymentStudent", paymentStudent)
    setTimeout(() => {
      setpaymentPage(true);
      setCourse("ADCA");
    }, 100);

  }
  const PaymentPageTying = (item) => {
    const paymentStudent = JSON.stringify(item);
    localStorage.setItem("paymentStudent", paymentStudent);
    setTimeout(() => {
      setpaymentPage(true);
      setCourse("TYPING");
    }, 100);

  }
  // /////// franchise time start //////////

  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await Http({
          url: `/payment/getpayment`,
          method: "get",
          headers: {
            'Authorization': `Bearer ${token}`
          },
          data: {},
        });
        setpaymentDetail(res?.data?.paymentDetail)

      } catch (error) {

      }
    }
    fetchData()
  }, [token])

  ///////// franchise time end //////////

  //////// directortime ///////////
  const RequestAssept = async ({ _id, course }) => {
    try {
      let res = await Http({
        url: `/payment/updatepayment`,
        method: "patch",
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: { payment: true, studentid: _id, course }
      })
      if (res?.data?.message === "Payment updated successfully") {
        window.location.reload()
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className='overflow-hidden'>
      {
        loading ?
          (
            <>
              <div className="w-[95%] mt-8 mx-auto">
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-700 bg-gray-200 shadow-md rounded-lg overflow-hidden">
                    <thead className="bg-gray-700">
                      <tr>
                        <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                        </th>
                        <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                        </th>
                        <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                        </th>
                        <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                        </th>

                      </tr>
                    </thead>
                    <tbody className="divide-y divide-white ">
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) :
          (
            <>
              <div className='w-[95%] h-[520px] overflow-x-auto  mt-8 mx-auto relative'>

                <div className="w-full shadow-md sm:rounded-lg ">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-700">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Student Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Franchise Name
                        </th>
                        {
                          loginUser.role === "director" &&
                          <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                            ADCA Payment Request
                          </th>
                        }
                        {
                          loginUser.role === "director" &&
                          <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                            Typing Payment Request
                          </th>
                        }
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Father Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Mother Name
                        </th>

                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Address
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Qualification
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Date of Birth
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Aadhaar Number
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Gender
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Status
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Mobile
                        </th>
                        {
                          loginUser.role === "director" &&
                          <>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                              ADCA Certificate
                            </th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                              Typing Certificate
                            </th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                              ADCA Marksheet
                            </th>
                            {/* <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                            Typing Marksheet
                            </th> */}
                          </>
                        }
                        {
                          loginUser.role === "franchise" &&
                          <>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                              Request ADCA Certificate
                            </th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                              Request Typing Certificate
                            </th>
                          </>
                        }

                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 text-center">
                      {baseStudents?.map((item, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.studentname}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.franchise_or_director_id?.franchisename}</td>
                          {
                            loginUser?.role === "director" && (
                              <>
                                {/* ADCA Payment */}
                                <td
                                  onClick={() => RequestAssept({ _id: item?._id, course: "ADCA" })}
                                  className={`px-6 py-4 whitespace-nowrap rounded-md shadow-md cursor-pointer text-white
          ${paymentDetail.some(
                                    (Pay) =>
                                      Pay?.studentid === item?._id &&
                                      Pay?.course === "ADCA"
                                  )
                                      ? paymentDetail.find(
                                        (Pay) =>
                                          Pay?.studentid === item?._id &&
                                          Pay?.course === "ADCA" &&
                                          Pay?.payment
                                      )
                                        ? "bg-green-600" // Payment Complete = green
                                        : "bg-yellow-500" // Payment Update = yellow
                                      : "bg-red-600"} // No Payment Request = red
        `}
                                >
                                  <button className="hover:underline">
                                    {paymentDetail.some(
                                      (Pay) =>
                                        Pay?.studentid === item?._id &&
                                        Pay?.course === "ADCA"
                                    ) ? (
                                      paymentDetail.find(
                                        (Pay) =>
                                          Pay?.studentid === item?._id &&
                                          Pay?.course === "ADCA" &&
                                          Pay?.payment
                                      )
                                        ? "ADCA Payment Complete"
                                        : "ADCA Payment Update"
                                    ) : (
                                      "No Request ADCA Payment"
                                    )}
                                  </button>
                                </td>

                                {/* Typing Payment */}
                                <td
                                  onClick={() => RequestAssept({ _id: item?._id, course: "TYPING" })}
                                  className={`px-6 py-4 whitespace-nowrap rounded-md shadow-md cursor-pointer text-white
          ${paymentDetail.some(
                                    (Pay) =>
                                      Pay?.studentid === item?._id &&
                                      Pay?.course === "TYPING"
                                  )
                                      ? paymentDetail.find(
                                        (Pay) =>
                                          Pay?.studentid === item?._id &&
                                          Pay?.course === "TYPING" &&
                                          Pay?.payment
                                      )
                                        ? "bg-green-600" // Payment Complete = green
                                        : "bg-yellow-500" // Payment Update = yellow
                                      : "bg-red-600"} // No Payment Request = red
        `}
                                >
                                  <button className="hover:underline">
                                    {paymentDetail.some(
                                      (Pay) =>
                                        Pay?.studentid === item?._id &&
                                        Pay?.course === "TYPING"
                                    ) ? (
                                      paymentDetail.find(
                                        (Pay) =>
                                          Pay?.studentid === item?._id &&
                                          Pay?.course === "TYPING" &&
                                          Pay?.payment
                                      )
                                        ? "Typing Payment Complete"
                                        : "Typing Payment Update"
                                    ) : (
                                      "No Request Typing Payment"
                                    )}
                                  </button>
                                </td>
                              </>
                            )
                          }

                          <td className="px-6 py-4 whitespace-nowrap">{item?.fathername}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.mothername}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.address}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.qualification}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{DateTime(item?.dateofbirth, "DDMMYYYY",)}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.aadharno}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.gender}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.status ? "Study Running" : "Study Complate"}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.mobile}</td>
                          {
                            loginUser.role === "director" &&
                            <>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <button onClick={() => A_CertificateHandler(item)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">ADCA Certificate</button>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <button onClick={() => T_CertificateHandler(item)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Typing Certificate</button>
                              </td>
                              <td onClick={() => MarkSheetHandler(item)} className="px-6 py-4 whitespace-nowrap">
                                <button className="font-medium text-blue-600 dark:text-blue-500 hover:underline">ADCA Marksheet</button>
                              </td>
                              {/* <td onClick={() => TyingMarkSheetHandler(item)} className="px-6 py-4 whitespace-nowrap">
                                <button className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Typing Marksheet</button>
                              </td> */}
                            </>
                          }
                          {
                            loginUser?.role === "franchise" && (
                              <>
                                {/* ADCA Certificate */}
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <button
                                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                    onClick={() => PaymentPageADCA(item)}
                                  >
                                    {paymentDetail?.some(
                                      (Pay) =>
                                        Pay?.studentid === item?._id &&
                                        Pay?.course === "ADCA"
                                    ) ? (
                                      paymentDetail?.find(
                                        (Pay) =>
                                          Pay?.studentid === item?._id &&
                                          Pay?.course === "ADCA"
                                      )?.payment
                                        ? "Complete"
                                        : "Pending"
                                    ) : (
                                      "Request ADCA Certificate"
                                    )}
                                  </button>
                                </td>

                                {/* Typing Certificate */}
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <button
                                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                    onClick={() => PaymentPageTying(item)}
                                  >
                                    {paymentDetail?.some(
                                      (Pay) =>
                                        Pay?.studentid === item?._id &&
                                        Pay?.course === "TYPING"
                                    ) ? (
                                      paymentDetail?.find(
                                        (Pay) =>
                                          Pay?.studentid === item?._id &&
                                          Pay?.course === "TYPING"
                                      )?.payment
                                        ? "Complete"
                                        : "Pending"
                                    ) : (
                                      "Request Typing Certificate"
                                    )}
                                  </button>
                                </td>
                              </>
                            )
                          }



                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )
      }
      {
        CertificateCreate && <ACreatecertificate />
      }
      {
        T_CertificateCreate && <TCreatecertificate />
      }
      {
        Marksheet && <AMarksheet />
      }
      {
        TypingMarksheet && <TMarksheet />
      }
      {
        paymentPage && <PaymentMethod course={course} />
      }
    </div>
  )
}

export default Totalcertificate
