import React from 'react';
import { IoLocationSharp } from "react-icons/io5";
import { FaPhoneVolume } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
// import { FaHandPointRight } from "react-icons/fa";


function Footer() {
    const mobileNumberHandler = (phoneNumber) => {
        window.open(`tel:${phoneNumber}`, '_blank')
    }
    return (
        <footer className='w-full'>

            <div className={`footer1bg bg-gray-900 text-white py-14`}>
                <div className='w-[90%] mx-auto md:flex justify-between'>
                    <div className='w-full md:w-[30%] px-6 md:px-14 mb-8 md:mb-0'>
                        <h1 className='mb-3 text-2xl font-semibold'>Adarsh Jankalyan Trust Pvt. Ltd.</h1>
                        <div className='border-b-2 text-white w-24 mb-6'></div>
                        <p className='text-gray-100 font-mono'>Adarsh Jankalyan Trust Pvt. Ltd. is an ISO 9001:2015 certified company which is recognized by JAS-ANZ and registered under the Company Act 2015, Ministry of Company Affairs, and Government of India with Certificate identity number .</p>
                    </div>
                    <div className='w-full md:w-[25%] px-6 mb-8 md:mb-0'>
                        <h1 className='mb-3 text-2xl font-semibold'>Registered Office</h1>
                        <div className='border-b-2 text-white w-24 mb-6'></div>

                        <h2 className='mb-2 font-semibold flex items-center'><IoLocationSharp className='mr-4'/>Address:</h2>
                        <p className='mb-2 text-gray-300 font-normal ml-8'>Dadanpur, Maker, Pir Maker, Saran Bihar 841215</p>

                        <h2 className='mb-2 font-semibold flex items-center '><FaPhoneVolume className='mr-4'/>Mobile No:</h2>
                        <p onClick={() => mobileNumberHandler('7004322539')} className='mb-2 cursor-pointer text-gray-300 font-normal ml-8'>+91 7004322539</p>

                        <h2 className='mb-2 font-semibold flex items-center'><MdEmail className='mr-4'/>Email:</h2>
                        <p onClick={() => window.location.href = "mailto:ajktrust@gmail.com"}  className='text-gray-300 font-normal ml-8'>ajktrust@gmail.com</p>
                    </div>
                    <div className='w-full md:w-[25%] px-6 mb-8 md:mb-0'>
                        <h1 className='mb-3 text-2xl font-semibold'>Corporate Office</h1>
                        <div className='border-b-2 text-white w-24 mb-6'></div>

                        <h2 className='mb-2 font-semibold flex items-center'><IoLocationSharp className='mr-4'/>Address:</h2>
                        <p className='mb-2 text-gray-300 font-normal ml-8'>Dadanpur, Maker, Pir Maker, Saran Bihar 841215</p>

                        <h2 className='mb-2 font-semibold flex items-center'><FaPhoneVolume className='mr-4'/>Mobile No:</h2>
                        <p onClick={() => mobileNumberHandler('7004322539')} className='mb-2 text-gray-300 font-normal ml-8 cursor-pointer '>+91 7004322539</p>

                        <h2 className='mb-2 font-semibold flex items-center'><MdEmail className='mr-4'/>Email:</h2>
                        <p onClick={() => window.location.href = "mailto:ajktrust@gmail.com"} className='text-gray-300 font-normal ml-8'>ajktrust@gmail.com</p>
                    </div>
                    {/* <div className='w-full md:w-[20%] px-6'>
                        <h1 className='mb-3 text-2xl font-semibold'>Hot Links</h1>
                        <div className='border-b-2 text-white w-24 mb-6'></div>

                        <h2 className='text-gray-300 font-normal flex items-center'> <FaHandPointRight size={25} className='mr-2'/> Home</h2>
                        <h2 className='text-gray-300 font-normal flex items-center'> <FaHandPointRight size={25} className='mr-2'/> Who We Are</h2>
                        <h2 className='text-gray-300 font-normal flex items-center'> <FaHandPointRight size={25} className='mr-2'/> Registration Verification</h2>
                        <h2 className='text-gray-300 font-normal flex items-center'> <FaHandPointRight size={25} className='mr-2'/> Result Verification</h2>
                        <h2 className='text-gray-300 font-normal flex items-center'> <FaHandPointRight size={25} className='mr-2'/> Company Certificate</h2>
                        <h2 className='text-gray-300 font-normal flex items-center'> <FaHandPointRight size={25} className='mr-2'/> ISO Certificate</h2>
                        <h2 className='text-gray-300 font-normal flex items-center'> <FaHandPointRight size={25} className='mr-2'/> Trademark Certificate</h2>
                        <h2 className='text-gray-300 font-normal flex items-center'> <FaHandPointRight size={25} className='mr-2'/> Contact Us</h2>
                    </div> */}
                </div>
            </div>
            <div className='bg-[#0A2A8A] border-t border-white'>
                <div className='w-[90%] mx-auto md:flex justify-between'>
                    <div className='py-5'>
                        <h1 className='text-white'>©2018-2025 All Rights Reserved | <span className='ml-1 text-yellow-500 font-medium'>Adarsh Jankalyan Trust Pvt. Ltd.</span></h1>
                    </div>
                    <div className='py-5'>
                        <h1 onClick={() => window.location.href = "mailto:devworkvishal@gmail.com"} className='text-white cursor-pointer flex'>Planted By <span className='ml-1 text-yellow-500 font-medium'> Vishal Kumar</span></h1>
                    </div>

                </div>
            </div>

        </footer>
    )
}

export default Footer;
