import React, { useContext } from 'react';
import Http from '../../../Services/Http';
import { MyContext } from '../../../Context/MyContextProvider';
import ORImage from '../../../Assets/raviQRUPI.jpeg'

function PaymentMethod(props) {
  console.log("props",props);
  
  const token = localStorage.getItem('token');
  const { setpaymentPage, } = useContext(MyContext);
  const paymentStudent = localStorage.getItem('paymentStudent');
  const item = JSON.parse(paymentStudent);
  const requestHandler = async () => {
    if (item) {
      try {
        let res = await Http({
          url: `/payment/createpayment`,
          method: "post",
          headers: {
            'Authorization': `Bearer ${token}`
          },
          data: { course: props?.course, studentid: item?._id }
        })
        if (res?.data?.message === "Payment created successfully") {
          setpaymentPage(false)
          window.location.reload()
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-8 max-w-xs w-full">
        <h2 className="text-2xl font-bold mb-4">UPI Payment</h2>
        <div className="bg-gray-100 rounded-lg p-4 mb-4">
          <div>
            <img src={ORImage} alt='ORcode' />
          </div>
          <button onClick={() => requestHandler()} className="bg-blue-500 text-white rounded-md px-4 py-2 mt-2 hover:bg-blue-600 focus:outline-none focus:bg-blue-600">Submit</button>
        </div>
        <button onClick={() => setpaymentPage(false)} className="text-sm text-gray-500 hover:underline focus:outline-none">Cancel</button>
      </div>
    </div>
  );
}

export default PaymentMethod;
