import React, { useContext } from 'react'
import TotalStudent from './TotalStudent'
import StudentRegister from './StudentRegister'
import { MyContext } from '../../../Context/MyContextProvider';

function StudentPage() {
    const { StudentNavbar, setStudentNavbar } = useContext(MyContext);
    const user = localStorage.getItem('userdata');
    const loginUser = JSON.parse(user);

    const StudentHandler = () => {
        setStudentNavbar(false)
    }
    const trigerListing = () => {
        setStudentNavbar(true)
    }
    return (
        <div>
            <div className="w-full sidebar-same border-b h-16 ">
                <div className="w-[95%] mx-auto px-4 py-2 flex justify-end">
                    <div className="w-[80%]">

                    </div>
                    <div className="w-[40%] sm:w-[20%] pl-2 flex justify-between">
                        <div onClick={StudentHandler}
                            className={!StudentNavbar ? 'cursor-pointer text-white  border-b-2 border-[#2e3193] hover:border-white  mt-2 px-4 rounded  md:hover:bg-transparent   md:p-0 dark:text-white   dark:hover:text-white md:dark:hover:bg-transparent' : 'cursor-pointer text-white  border-b-2 border-white hover:border-[#ff0000]  mt-2 px-4  rounded  md:hover:bg-transparent   md:p-0 dark:text-white   dark:hover:text-white md:dark:hover:bg-transparent'}>
                            Student
                        </div>
                        {
                            loginUser?.role === "franchise" &&
                            <button onClick={trigerListing} data-tooltip-target="tooltip-new" type="button"
                                className="inline-flex items-center justify-center w-10 h-10 font-medium bg-[#ff0000] rounded-full hover:bg-[#e63535] group focus:ring-4 focus:ring-[#ff0000] focus:outline-none dark:focus:ring-[#ff0000]">
                                <svg className="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 18 18">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                        d="M9 1v16M1 9h16" />
                                </svg>
                                <span className="sr-only">New item</span>
                            </button>
                        }
                        
                    </div>
                </div>
            </div>
            {
                StudentNavbar ? <StudentRegister /> : <TotalStudent />
            }
        </div>
    )
}

export default StudentPage