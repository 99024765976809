import React, { useContext, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Http from '../../../Services/Http';
import { MyContext } from '../../../Context/MyContextProvider';

function StudentRegister() {
    const { setStudentNavbar } = useContext(MyContext);
    const user = localStorage.getItem('userdata');
    const loginUser = JSON.parse(user);
    let id = loginUser?.franchiserid?._id;
    const [image, setimage] = useState('')

    const handleImageUpload = (event) => {
        const imageFile = event.target.files[0];
        if (imageFile) {
            const reader = new FileReader();
            reader.onload = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");
    
                    // Set canvas dimensions to the image dimensions
                    canvas.width = img.width;
                    canvas.height = img.height;
    
                    // Draw the image on the canvas
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    
                    // Adjust compression quality in a loop until file size is <= 10KB
                    let quality = 0.9; // Start with high quality
                    let compressedImageData;
                    do {
                        compressedImageData = canvas.toDataURL("image/jpeg", quality);
                        const base64Length = compressedImageData.length - compressedImageData.indexOf(",") - 1;
                        const fileSizeKB = (base64Length * 3) / 4 / 1024; // Convert Base64 size to KB
                        if (fileSizeKB <= 10) break; // Stop if file size is within limit
                        quality -= 0.1; // Reduce quality
                    } while (quality > 0);
    
                    if (compressedImageData) {
                        // Do something with the compressed image data
                        setimage(compressedImageData);
                    } else {
                        console.error("Failed to compress the image within 10KB limit.");
                    }
                };
            };
            reader.readAsDataURL(imageFile);
        }
    };
    

    const initialValues = {
        studentname: '',
        fathername: '',
        address: '',
        mothername: '',
        qualification: '',
        dateofbirth: '',
        aadharno: '',
        gender: '',
        status: '',
        mobile: '',
        startDate: '',
        toenddate: '',
    };

    const validationSchema = Yup.object({
        studentname: Yup.string().min(3, 'Must be at least 3 characters').required('Required'),
        fathername: Yup.string().min(3, 'Must be at least 3 characters').required('Required'),
        address: Yup.string().min(20, 'Must be at least 20 characters').required('Required'),
        mothername: Yup.string().min(3, 'Must be at least 3 characters').required('Required'),
        qualification: Yup.string().required('Required'),
        dateofbirth: Yup.date().required('Required'),
        aadharno: Yup.number().required('Required').min(12, "Minimum 12 digits").test('len', 'Aadhar number must be 12 digits', val => val && val.toString().length === 12),
        gender: Yup.string().required('Required'),
        status: Yup.string().required('Required'),
        startDate: Yup.date().required('Required'),
        toenddate: Yup.date().required('Required'),
        mobile: Yup.string().required('Required').min(10, "minimum 10 digit").max(10, "maximum 10 digit"),
    });

    const onSubmit = async (values) => {
        const franchise_or_director_id = id;

        const startDate = values.startDate;
        const toenddate = values.toenddate;

        const studentname = values.studentname;
        const fathername = values.fathername;
        const address = values.address;
        const mothername = values.mothername;
        const qualification = values.qualification;
        const dateofbirth = values.dateofbirth;
        const aadharno = values.aadharno;
        const gender = values.gender;
        const status = values.status;
        const mobile = values.mobile;
        // Handle form submission here
        try {
            let res = await Http({
                url: `/student/createstudent`,
                method: "post",
                data: {
                    franchise_or_director_id, studentname, fathername,
                    address, mothername, qualification, dateofbirth, aadharno,
                    gender, status, mobile, image, startDate, toenddate
                }
            });
            if (res.data.message === "StudentSaved_201") {
                setTimeout(() => {
                    setStudentNavbar(false)
                }, 2000);
            }

        } catch (error) {
            console.log(`Server Catch Error ${error}`);
        }
    };
    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                <Form className="w-full mt-8 mx-auto">
                    <div className="w-[95%] h-screen bg-white mx-auto rounded-md border border-gray-200 flex justify-between">
                        <div className="w-[80%] ">
                            <div className="w-full py-3 px-4 bg-gray-700 rounded-tl-md text-white mx-auto border-b">
                                <h1 className="text-xl tracking-wider font-bold ">Student Registration</h1>
                            </div>
                            <div className="w-full p-4">
                                <div className="w-full flex gap-4">
                                    <div className="w-full md:w-[70%]">
                                        <label className="block py-2 px-2 rounded-sm tracking-wide">Student name </label>
                                        <Field
                                            type="text"
                                            name="studentname"

                                            className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />
                                        <p className="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name="studentname" component="p" />
                                        </p>
                                    </div>
                                    <div className="w-full md:w-[30%]">
                                        <label className="block py-2 px-2 rounded-sm tracking-wide">Father Name</label>
                                        <Field
                                            type="text"
                                            name="fathername"

                                            className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />
                                        <p className="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name="fathername" component="p" />
                                        </p>
                                    </div>
                                </div>
                                <div className='w-full p-4  flex'>
                                    <div>
                                        <label className="block py-2 px-2 rounded-sm tracking-wide">Student Photo</label>
                                        <input type='file' accept="image/*" className='cursor-pointer' onChange={handleImageUpload} />
                                    </div>
                                    {
                                        image && <img className='w-28 h-36' src={image} alt='im-1' />
                                    }

                                    
                                </div>
                                <div className="w-full flex gap-4">
                                    <div className="w-full md:w-[60%]">
                                        <label className="block py-2 px-2 rounded-sm tracking-wide">Address</label>
                                        <Field
                                            as="textarea"
                                            name="address"

                                            className="block w-full h-28 border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        ></Field>
                                        <p className="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name="address" component="p" />
                                        </p>
                                    </div>
                                    <div className="w-full md:w-[40%]">
                                        <div>
                                            <label className="block py-2 px-2 rounded-sm tracking-wide">Mother Name</label>
                                            <Field
                                                type="text"
                                                name="mothername"

                                                className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                            />
                                            <p className="text-red-600 text-sm mt-1 ml-2">
                                                <ErrorMessage name="mothername" component="p" />
                                            </p>
                                        </div>
                                        <div>
                                            <label className="block py-2 px-2 rounded-sm tracking-wide">Qualification</label>
                                            <Field
                                                type="text"
                                                name="qualification"

                                                className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                            />
                                            <p className="text-red-600 text-sm mt-1 ml-2">
                                                <ErrorMessage name="qualification" component="p" />
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="w-full flex gap-4 justify-between">
                                    <div className="w-full md:w-[30%]">
                                        <label className="block py-2 px-2 rounded-sm tracking-wide">Date Of  Birth </label>
                                        <Field
                                            type="date"
                                            name="dateofbirth"

                                            className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />
                                        <p className="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name="dateofbirth" component="p" />
                                        </p>
                                    </div>
                                    <div className="w-full md:w-[30%]">
                                        <label className="block py-2 px-2 rounded-sm tracking-wide">Aadhar No</label>
                                        <Field
                                            type="number"
                                            name="aadharno"

                                            className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />
                                        <p className="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name="aadharno" component="p" />
                                        </p>
                                    </div>
                                    <div className="w-full md:w-[30%]">
                                        <label className="block py-2 px-2 rounded-sm tracking-wide">Male/Female</label>
                                        <Field
                                            as="select"

                                            name="gender"

                                            className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        >
                                            <option value="">Select</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </Field>
                                        <p className="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name="gender" component="p" />
                                        </p>
                                    </div>
                                    <div className="w-full md:w-[30%]">
                                        <label className="block py-2 px-2 rounded-sm tracking-wide">Status</label>
                                        <Field

                                            name="status"
                                            as="select"
                                            className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        >
                                            <option value="">Select</option>
                                            <option value="true">Study Running</option>
                                            <option value="false">Study Complate</option>
                                        </Field>
                                        <p className="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name="status" component="p" />
                                        </p>
                                    </div>
                                </div>
                                <div className="w-full flex gap-4 justify-between">
                                    <div className="w-full md:w-[50%]">
                                        <label className="block py-2 px-2 rounded-sm tracking-wide">Mobile No</label>
                                        <Field
                                            type="number"
                                            name="mobile"

                                            className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />
                                        <p className="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name="mobile" component="p" />
                                        </p>
                                    </div>
                                    <div className="w-full md:w-[30%]">
                                        <label className="block py-2 px-2 rounded-sm tracking-wide">Start Date </label>
                                        <Field
                                            type="date"
                                            name="startDate"

                                            className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />
                                        <p className="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name="dateofbirth" component="p" />
                                        </p>
                                    </div>
                                    <div className="w-full md:w-[30%]">
                                        <label className="block py-2 px-2 rounded-sm tracking-wide">To Date </label>
                                        <Field
                                            type="date"
                                            name="toenddate"

                                            className="block w-full border border-gray-400 focus:bg-white rounded-md bg-[#f5f5f5] outline-none px-4 py-2 text-sm "
                                        />
                                        <p className="text-red-600 text-sm mt-1 ml-2">
                                            <ErrorMessage name="dateofbirth" component="p" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-[20%] border-l border-gray-300 my-5">
                            <div className="w-full flex justify-evenly">

                                <button
                                    type="submit"
                                    className="focus:outline-none text-white bg-[#ff0000] hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-md text-sm px-5 py-2.5 me-2 mb-2 dark:bg-[#ff0000] dark:hover:bg-red-700 dark:focus:ring-red-900"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default StudentRegister;