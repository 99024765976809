import React, { useContext, useEffect, useState } from 'react'
import Http from '../../../Services/Http';
import { FaFileCsv } from "react-icons/fa";
import { DateTime } from 'date-time-helper-x';
import { MyContext } from '../../../Context/MyContextProvider';
import UpdateStudent from './UpdateStudent';

function TotalStudent() {
  const { StudentToggle, setStudentToggle } = useContext(MyContext)
  const [students, setStudents] = useState([]);
  const [baseStudents, setbaseStudents] = useState([])
  const user = localStorage.getItem('userdata');
  const token = localStorage.getItem('token');
  const loginUser = JSON.parse(user);
  let newUserlogin = loginUser?.franchiserid?._id
  let studentloginid = loginUser?.studentid?._id
  const [loading, setloading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await Http({
          url: `/student/getstudent`,
          method: "get",
          headers: {
            'Authorization': `Bearer ${token}`
        },  
          
          data: {}
        });
        const students = res?.data?.students;
        setStudents(students);
        setloading(false);
      } catch (error) {
        console.log(`Server Catch Error ${error}`);
        setloading(false);
      }
    };

    fetchData(); // Call fetchData here

  }, [token]); // Empty dependency array



  useEffect(() => {
    if (loginUser.role === "director") {
      setbaseStudents(students);

    } else if (loginUser?.role === "franchise") {
      const student = students.filter((item) => {
        return item?.franchise_or_director_id?._id === newUserlogin
      })
      setbaseStudents(student)

    } else if (loginUser?.role === "student") {
      const student = students.filter((item) => {
        return item?._id === studentloginid && item?.franchise_or_director_id?._id === newUserlogin
      })
      setbaseStudents(student)
    }
  }, [newUserlogin, loginUser?.role, students, studentloginid])

  // Function to convert array of objects to CSV format
  const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(',') + '\n';
    const rows = data.map(item => Object.values(item).join(',')).join('\n');
    return header + rows;
  };

  // Function to handle download button click
  const handleDownload = () => {
    const csv = convertToCSV(baseStudents);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Students_data.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };
  const updateStudentHaderl = (item) => {
    localStorage.setItem('studentItemData', JSON.stringify(item));
    setTimeout(() => {
      setStudentToggle(true);
    }, 200);
  }
  const deleteStudentHaderl = async (item) => {

    let _id = item?._id;
    const confirmDelete = window.confirm("Are you sure you want to delete this student?");
    if (confirmDelete) {
      try {
        let res = await Http({
          url: `/student/deletestudent`,
          method: "post",
          headers: {
            'Authorization': `Bearer ${token}`
        },
          data: { _id }
        });
        console.log(res?.data?.message);
        if (res?.data?.message === "deletestudent_200") {
          window.location.reload();

        }
      } catch (error) {
        console.log(`Server Catch Error ${error}`);

      }
    }

  }

  return (
    <div>
      {
        loading ?
          (
            <>
              <div className="w-[95%] mt-8 mx-auto">
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-700 bg-white shadow-md rounded-lg overflow-hidden">
                    <thead className="bg-gray-700">
                      <tr>
                        <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                        </th>
                        <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                        </th>
                        <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                        </th>
                        <th className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">

                        </th>

                      </tr>
                    </thead>
                    <tbody className="divide-y divide-white ">
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                      <tr className="animate-pulse">
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-center"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) :
          (
            <>
              <div className='w-[95%] mt-8 mx-auto relative'>
                <div className="w-full shadow-md sm:rounded-lg overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-700">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Student Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Franchise Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Father Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Mother Name
                        </th>

                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Address
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Qualification
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Date of Birth
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Start Date
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          To Date
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Aadhaar Number
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Gender
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Status
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                          Mobile
                        </th>
                        {
                          loginUser?.role !== "student" &&
                          <>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                              Edit
                            </th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-100 uppercase tracking-wider text-center">
                              Delete
                            </th>
                          </>
                        }

                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 text-center">
                      {baseStudents?.map((item, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.studentname}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.franchise_or_director_id?.franchisename}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.fathername}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.mothername}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.address}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.qualification}</td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {item?.dateofbirth && DateTime(item.dateofbirth, "DDMMYYYY")}
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap">
                            {item?.startDate && DateTime(item.startDate, "DDMMYYYY")}
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap">
                            {item?.toenddate && DateTime(item.toenddate, "DDMMYYYY")}
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap">{item?.aadharno}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.gender}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.status ? "Study Running" : "Study Complate"}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item?.mobile}</td>
                          {
                            loginUser?.role !== "student" &&
                            <>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <button onClick={() => updateStudentHaderl(item)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</button>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <button onClick={() => deleteStudentHaderl(item)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Delete</button>
                              </td>
                            </>
                          }

                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* Download button */}
                {
                  loginUser?.role !== "student" &&
                  <div className="flex justify-center mt-1 cursor-pointer absolute top-0 right-2 text-white bg-gray-700">
                    <div onClick={handleDownload} className=" font-bold py-2 px-4 rounded">
                      <FaFileCsv size={30} />
                    </div>
                  </div>
                }

              </div>
            </>
          )
      }
      {
        StudentToggle && <UpdateStudent />
      }

    </div>
  )
}

export default TotalStudent
