import React, { useState } from 'react'
import Footer from '../../Components/Footer'
import Navbar from '../../Components/Navbar'
import MobileNavbar from '../../Components/MobileNavbar'
import logo from '../../../Assets/logo3.png'
import Http from '../../../Services/Http'

function Cverify() {
    const [certificateCode, setCertificateCode] = useState('');
    const [verificationStatus, setVerificationStatus] = useState('');
    const [certificate, setcertificate] = useState({});
    const [M, setM] = useState('');
    console.log("M",M);
    const handleVerify = async () => {
        try {
            let res = await Http({
                url: `/payment/certificateverfy`,
                method: `post`,
                data: { serialno: certificateCode }
            });

            if (res.data?.success === true) {
                setcertificate(res?.data?.certificate)
                setVerificationStatus('Certificate Verified!');
            } else if (res.data?.success === false) {
                setVerificationStatus('Invalid Certificate Number');
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div>
            <div className='hidden md:block'>
                <Navbar />
            </div>
            <div className=' md:hidden'>
                <MobileNavbar />
            </div>
            <div className='mt-10 mb-10 md:mt-28'>
                <div className="flex items-center justify-center">
                    <div className="w-[90%] md:w-[70%] mx-auto bg-white  rounded-lg shadow-md md:flex">
                        <div className="w-full md:w-[500px] flex-shrink-0 mr-4 bg-[#0a2a8a] p-8">
                            <img
                                className="h-full w-full"
                                src={logo}
                                alt="Company Logo"
                            />
                        </div>
                        <div className="w-full p-8">
                            <h1 className="text-3xl font-semibold mb-6 text-center">Certificate Verification</h1>
                            <div className="w-full  mx-auto">
                                <input
                                    type="text"
                                    placeholder="Enter certificate code"
                                    className="w-full border border-gray-300 rounded-md px-3 py-2 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    value={certificateCode}
                                    onChange={(e) => setCertificateCode(e.target.value)}
                                />
                                <button
                                    className="w-full bg-[#0a2a8a] text-white rounded-md py-2 transition duration-300 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    onClick={handleVerify}
                                >
                                    Verify Certificate
                                </button>
                                <p className={`mt-4 text-center 
                                    ${verificationStatus === "Certificate Verified!" ? "text-green-600 font-semibold" : "text-red-600 font-semibold"}`}
                                >
                                    {verificationStatus}</p>
                                <div className="w-full mt-4 text-center">
                                    {
                                        verificationStatus === "Certificate Verified!" &&
                                        <div className="flex flex-col items-start mx-auto p-4 border border-gray-400 rounded">
                                            <div className="flex mb-2">
                                                <span className="font-semibold text-gray-700 w-40">Name:</span>
                                                <span>{certificate?.studentid?.studentname}</span>
                                            </div>
                                            <div className="flex mb-2">
                                                <span className="font-semibold text-gray-700 w-40">Father Name:</span>
                                                <span>{certificate?.studentid?.fathername}</span>
                                            </div>
                                            <div className="flex mb-2">
                                                <span className="font-semibold text-gray-700 w-40">Certificate No:</span>
                                                <span>{certificate?.serialno}</span>
                                            </div>
                                            <div className="flex mb-2">
                                                <span className="font-semibold text-gray-700 w-40">Course:</span>
                                                <span>{certificate?.category}</span>
                                            </div>
                                            <div className="flex mb-2">
                                                <span className="font-semibold text-gray-700 w-40">Start Date:</span>
                                                <span>
                                                {(() => {
                                                        const date = new Date(certificate?.startdate);
                                                        // setM(date)
                                                        return date.toLocaleString('default', { month: 'long' });
                                                    })()}
                                                </span>
                                            </div>
                                            <div className="flex mb-2">
                                                <span className="font-semibold text-gray-700 w-40">End Date:</span>
                                                <span>
                                                {(() => {
                                                        const date = new Date(certificate?.enddate);
                                                        // setM(date)
                                                        return date.toLocaleString('default', { month: 'long' });
                                                    })()}
                                                </span>
                                            </div>
                                            <div className="flex mb-2">
                                                <span className="font-semibold text-gray-700 w-40">Issue Date:</span>
                                                <span>
                                                    {(() => {
                                                        const issuedate = certificate?.issuedate;
                                                        const parts = issuedate.split(":");
                                                        const extractedDate = parts[1].trim();

                                                        return extractedDate
                                                    })()}
                                                </span>

                                            </div>
                                            <div className="flex mb-2">
                                                <span className="font-semibold text-gray-700 w-40">Grade:</span>
                                                <span>{(()=>{
                                                   const grade = certificate?.MarksSheet_Id?.grade;
                                                   return grade.split("%");
                                                })()}</span>
                                            </div>
                                            <div className="flex mb-2">
                                                <span className="font-semibold text-gray-700 w-40">Percentage:</span>
                                                <span>{certificate?.MarksSheet_Id?.Percentage}</span>
                                            </div>

                                        </div>
                                    }


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Cverify